/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import {
  BackgroundCircle, Item, LineLock, StyledImage,
} from './styles';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import MicrowavePhilco from '../../images/microondas_philco.png';
import GrillPhilco from '../../images/grill_philco.png';
import Airfryer from '../../images/airfryer.svg';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';

const CavalinhoCarousel = ({ active, activeIndex }) => {
  const carouselRef = React.useRef();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 501, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  React.useEffect(() => {
    if (carouselRef.current && typeof active === 'number') {
      const slideIndex = activeIndex === -1 ? 0 : activeIndex;
      carouselRef.current.goToSlide(slideIndex, true);
    }
  }, [active]);

  return (
    <Carousel responsive={responsive} ref={carouselRef} partialVisible>
      <Item>
        <div>
          <h1>Sorteio 01:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 Fone</h3>
          <BackgroundCircle
            active={active === 125}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={HeadphoneJbl}
            alt="Fone"
            style={active === 125
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 125}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 125}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 125 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 02:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 Grill</h3>
          <BackgroundCircle active={active === 250}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={GrillPhilco}
            alt="Grill"
            style={active === 250
              ? { height: '180px', width: '250px' }
              : { height: '160px', width: '180px', marginTop: '-110px' }}
            active={active < 250}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 250}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 250 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 03:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 Alexa Echo Pop</h3>
          <BackgroundCircle
            active={active === 375}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={AlexaEchoPop}
            alt="Alexa Echo Pop"
            style={active === 375
              ? { height: '180px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 375}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 375}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 375 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 04:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 Airfryer</h3>
          <BackgroundCircle
            active={active === 500}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={Airfryer}
            alt="Airfryer"
            style={active === 500
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 500}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 500}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 500 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 05:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 Fone</h3>
          <BackgroundCircle active={active === 625}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={HeadphoneJbl}
            alt="Fone"
            style={active === 625
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 625}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 625}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 625 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 06:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 AirFryer</h3>
          <BackgroundCircle
            active={active === 750}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={Airfryer}
            alt="Airfryer"
            style={active === 750
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 750}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 750}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 750 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 07:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Alexa Echo Pop</h3>
          <BackgroundCircle active={active === 875}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={AlexaEchoPop}
            alt="Alexa Echo Pop"
            style={active === 875
              ? { height: '180px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 875}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 875}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 875 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 08:</h1>
          <h2>Ganhador 01:</h2>
          <h3>01 MICRO-ONDAS</h3>
          <BackgroundCircle
            active={active === 1000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={MicrowavePhilco}
            alt="Micro-ondas"
            style={active === 1000
              ? { height: '130px', width: '220px' }
              : { height: '110px', width: '180px', marginTop: '-110px' }}
            active={active < 1000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 1000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 1000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
    </Carousel>
  );
};

CavalinhoCarousel.propTypes = {
  active: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default CavalinhoCarousel;
