/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  ChevronLeft,
  ChevronRight,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import axios from 'axios';
import LpFooter from 'components/lp-footer';
import SignupForm from 'components/signup-form';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import CavalinhoCarousel from 'components/cavalinho-carousel';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';
import AliInCellphone from '../../images/AliInCellphone.png';
import AlivieOBolsoComAli from '../../images/alivie-o-bolso-com-ali.svg';
import CavalinhoPrizes from '../../images/cavalindo_prizes2.png';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import ManPlaying from '../../images/man_playing.png';
import HappyWoman from '../../images/woman_happy.svg';
import CavalinhoLogo from '../../images/wiipo_ali_cavalinho.png';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import MicrowavePhilco from '../../images/microondas_philco.png';
import GrillPhilco from '../../images/grill_philco.png';
import Airfryer from '../../images/airfryer.svg';
import {
  Answer,
  ArrowButton,
  ArrowIcon,
  ArrowsContainer,
  BackgroundCircle,
  BlackDivider,
  Button,
  CampaignProgress,
  CarouselBox,
  Container,
  Content,
  FaqBox,
  FaqContainer,
  FaqItem,
  Footer,
  Header,
  ImageContainer,
  LeftContent,
  LineLock,
  Prize,
  PrizeList,
  PrizeListContent,
  PrizesBox,
  Progress, ProgressItem, ProgressList, Question, Regulation, RegulationBox, RightContent,
  StyledImage,
} from './styles';
import 'react-multi-carousel/lib/styles.css';

const CavalinhoLandingPageContent = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [registrationCount, setRegistrationCount] = React.useState(null);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const stages = [125, 250, 375, 500, 625, 750, 875, 1000];

  const calculateStage = (registrationCountFunc) => {
    let currentStage = 0;

    // eslint-disable-next-line max-len
    const nextStage = stages.find((stage) => registrationCountFunc < stage) || stages[stages.length - 1];
    const currentStageIndex = stages.indexOf(nextStage);
    if (nextStage === stages[stages.length - 1] && registrationCountFunc < stages[stages.length - 1]) {
      currentStage = stages[currentStageIndex - 1];
    } else if (currentStageIndex === stages.length - 1) {
      currentStage = stages[currentStageIndex];
    } else if (currentStageIndex > 0) {
      currentStage = stages[currentStageIndex - 1];
    }

    const remainingRegistrations = nextStage - registrationCountFunc;
    return {
      currentStage,
      nextStage,
      remainingRegistrations,
      currentStageIndex,
    };
  };

  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    const checkRegistrationCount = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages-subscriptions/count?landingPagePath=${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setRegistrationCount(response.data.validSubscriptions);
        }
      } catch (error) {
        setRegistrationCount(0);
      }
    };

    checkRegistrationCount();
  }, [companyMgm]);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScrollLeft = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft -= container.offsetWidth;
    setScrollPosition(container.scrollLeft - container.offsetWidth);
  };

  const handleScrollRight = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft += container.offsetWidth;
    setScrollPosition(container.scrollLeft + container.offsetWidth);
  };

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Preciso contratar o crédito Ali para participar dos sorteios?',
      answer: 'Não. Você não precisa contratar o crédito Wiipo+Ali para participar dos sorteios. Inscreva-se e concorra aos prêmios normalmente! Aproveite também para fazer uma simulação de crédito justo e com desconto em folha. Veja como podemos ajudar você a encontrar a melhor solução para suas necessidades financeiras.',
    },
    {
      question: 'Até quando poderei participar e concorrer aos prêmios?',
      answer: 'Para participar e concorrer, você deverá fazer seu cadastro preenchendo o formulário na página <a href="https://www.ali.com.vc/alivie-o-bolso/transportescavalinho">www.ali.com.vc/alivie-o-bolso/transportescavalinho</a> até o dia 15/02.',
    },
    {
      question: 'Meu benefício Ali já está ativado. Posso participar?',
      answer: 'Sim. Basta você se inscrever no formulário da página <a href="https://www.ali.com.vc/alivie-o-bolso/transportescavalinho">www.ali.com.vc/alivie-o-bolso/transportescavalinho</a> e você concorrerá a um dos prêmios desbloqueados.',
    },
    {
      question: 'Como os prêmios são desbloqueados?',
      answer: 'Os prêmios seguirão uma dinâmica de destrave por quantidade mínima de novos cadastros por sorteio. Ao final da ação, para liberar o último sorteio, a soma dos cadastros deverá ser 1.000. Assim, ao atingir as metas descritas abaixo, os prêmios serão desbloqueados à medida que cada meta for alcançada. Ou seja, cada sorteio requer um número específico de novos cadastros realizados, seguindo a ordem: <br /><br /> <u>Sorteio 01 (125 cadastros):</u><br />O ganhador leva um Fone <br /><u>Sorteio 02 (250 cadastros):</u> <br />O ganhador leva um Grill <br /><u>Sorteio 03 (375 cadastros):</u><br /> O ganhador leva uma Alexa Echo Pop <br /><u>Sorteio 04 (500 cadastros):</u><br /> O ganhador leva uma Air Fryer<br /><u>Sorteio 05 (625 cadastros):</u><br /> O ganhador leva um fone. <br /><u>Sorteio 06 (750 cadastros):</u><br /> O ganhador leva uma Air Fryer <br /><u>Sorteio 07 (875 cadastros):</u><br /> O ganhador leva uma Alexa Echo Pop <br /><u>Sorteio 08 (1000 cadastros):</u><br /> O ganhador leva um Micro-ondas',
    },
    {
      question: 'Como saberei quem ganhou os prêmios sorteados?',
      answer: 'Os ganhadores de cada sorteio serão divulgados nos canais de comunicação oficiais da sua empresa a cada sorteio.',
    },
    {
      question: 'Quais prêmios e quantos sorteios acontecerão?',
      answer: 'Os prêmios são: 02 Fone, 02 air fryer, 02 Alexa Echo Pop, 01 micro-ondas e 01 grill. Seguindo esta ordem: <br /><br /> <u>Sorteio 01:</u><br /> O ganhador leva um Fone <br /><u>Sorteio 02:</u> <br / >O ganhador leva um Grill <br /><u>Sorteio 03:</u><br /> O ganhador leva uma Alexa Echo Pop <br /><u>Sorteio 04:</u><br /> O ganhador leva uma AirFryer <br /><u>Sorteio 05:</u><br /> O ganhador leva um fone<br /><u>Sorteio 06:</u><br /> O ganhador leva uma AirFryer <br /><u>Sorteio 07:</u><br /> O ganhador leva uma Alexa Echo Pop <br /><u>Sorteio 08:</u><br /> O ganhador leva um Micro-ondas',
    },
    {
      question: 'Meu nome foi sorteado. Quando receberei meus prêmios?',
      answer: 'Os prêmios serão postados em até 20 dias úteis após o último sorteio e a confirmação de endereço completo por parte dos vencedores.',
    },
  ];

  const stageInfo = calculateStage(registrationCount);

  if (institutionalIsValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |
            {' '}
            {pathData.friendlyName ?? ''}
          </title>
        </Helmet>
        <Header>
          <img src={CavalinhoLogo} alt="Cavalinho logo" style={{ height: '50px' }} />
        </Header>
        <Content>
          <LeftContent>
            <ImageContainer>
              <img src={AlivieOBolsoComAli} alt="Alivie o Bolso" />
            </ImageContainer>
            <h1>Economize e concorra a prêmios com o benefício Ali</h1>
            <p>
              <b>Participe sem se preocupar:</b>
              {' '}
              você concorre e só utiliza o benefício Ali se quiser.
            </p>
          </LeftContent>
          <RightContent>
            <SignupForm company={pathData.id} origin="mgm" />
            <img src={HappyWoman} alt="Mulher contente, comemorando uma conquista enquanto segura um celular" style={{ height: '600px' }} />
          </RightContent>
        </Content>
        <BlackDivider>
          <div>
            <h1>
              Faltam apenas
              {' '}
              {stageInfo.remainingRegistrations}
              {' '}
              inscritos
            </h1>
            <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
          </div>
          <div>
            <img src={CavalinhoPrizes} alt="Prêmios" style={{ height: '70px' }} />
          </div>
        </BlackDivider>
        <PrizesBox>
          <h1>Você pode ganhar um desses prêmios incríveis</h1>
          <PrizeList>
            <PrizeListContent>
              <Prize>
                <h1>Fone</h1>
                <img src={HeadphoneJbl} alt="Fone" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Pop</h1>
                <img src={AlexaEchoPop} alt="Alexa Echo Pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Airfryer</h1>
                <img src={Airfryer} alt="Airfryer" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Grill</h1>
                <img src={GrillPhilco} alt="Grill" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Micro-ondas</h1>
                <img src={MicrowavePhilco} alt="Micro-ondas" style={{ height: '110px', width: '250px' }} />
              </Prize>
            </PrizeListContent>
          </PrizeList>
          <h2>*Imagens meramente ilustrativas</h2>
        </PrizesBox>
        {registrationCount !== null && (
          <CarouselBox>
            <div>
              <h1>Ajude seus amigos a economizar:</h1>
              <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
              {stageInfo.currentStage === 1150 ? (
                <h1>
                  Todos os sorteios desbloqueados!
                </h1>
              ) : (
                <>
                  <h1>
                    Faltam apenas
                    {' '}
                    {stageInfo.remainingRegistrations}
                    {' '}
                    inscritos
                  </h1>
                  <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
                </>
              )}
            </div>
            <CavalinhoCarousel active={stageInfo.currentStage} activeIndex={stageInfo.currentStageIndex - 1} />
          </CarouselBox>
        )}
        <CampaignProgress>
          <h1>Ajude seus amigos a economizar:</h1>
          <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
          <Progress>
            {stageInfo.currentStage === 1150 ? (
              <h1>
                Todos os sorteios desbloqueados!
              </h1>
            ) : (
              <>
                <h1>
                  Faltam apenas
                  {' '}
                  {stageInfo.remainingRegistrations}
                  {' '}
                  inscritos
                </h1>
                <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
              </>
            )}
            <ProgressList id="carousel">
              <ProgressItem>
                <h1>Sorteio 01:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Fone</h2>
                <br />
                <BackgroundCircle
                  active={stageInfo.currentStage === 125}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={HeadphoneJbl}
                  alt="Fone"
                  style={stageInfo.currentStage === 125
                    ? { height: '180px', width: '220px' }
                    : { height: '130px', width: '150px' }}
                  active={stageInfo.currentStage < 125}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 250}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 250
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 02:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Grill</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 250}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={GrillPhilco}
                  alt="Smartwatch haylou"
                  style={stageInfo.currentStage === 250
                    ? { height: '180px', width: '180px' }
                    : { height: '135px', width: '120px', margin: '-115px 20px 0 20px' }}
                  active={stageInfo.currentStage < 250}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 375}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 375
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 03:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Alexa Echo Pop</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 375}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={AlexaEchoPop}
                  alt="Alexa Echo Pop"
                  style={stageInfo.currentStage === 375
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '130px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 375}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 500}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 500
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 04:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Airfryer</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 500}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={Airfryer}
                  alt="Airfryer"
                  style={stageInfo.currentStage === 500
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '140px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 500}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 625}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 625
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 05:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Fone</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 625}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={HeadphoneJbl}
                  alt="Fone"
                  style={stageInfo.currentStage === 625
                    ? { height: '180px', width: '220px' }
                    : { height: '130px', width: '150px' }}
                  active={stageInfo.currentStage < 625}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 750}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 750
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 06:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Airfryer</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 750}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={Airfryer}
                  alt="Airfryer"
                  style={stageInfo.currentStage === 750
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '140px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 750}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 875}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 875
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 07:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Alexa Echo Pop</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 875}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={AlexaEchoPop}
                  alt="Alexa Echo Pop"
                  style={stageInfo.currentStage === 875
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '130px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 875}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 1000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 1000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 08:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Micro-ondas</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 1000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={MicrowavePhilco}
                  alt="Micro-ondas"
                  style={stageInfo.currentStage === 1000
                    ? { height: '130px', width: '220px', marginTop: '-75px' }
                    : { height: '90px', width: '140px', marginTop: '-75px' }}
                  active={stageInfo.currentStage < 1000}
                />
              </ProgressItem>
              <span style={{ minWidth: '20px' }} />
            </ProgressList>
            <ArrowsContainer>
              <ArrowButton aria-label="scroll left" onClick={handleScrollLeft} disabled={scrollPosition <= 0}>
                <ChevronLeft />
              </ArrowButton>
              <ArrowButton aria-label="scroll right" onClick={handleScrollRight}>
                <ChevronRight />
              </ArrowButton>
            </ArrowsContainer>
          </Progress>
        </CampaignProgress>
        <FaqBox>
          <div>
            <img src={ManPlaying} alt="Homem jogando" />
          </div>
          <div>
            <h2>Regras da Campanha</h2>
            <FaqContainer>
              {faqData.map((item, index) => (
                <FaqItem key={item.answer}>
                  <Question onClick={() => toggleFaq(index)}>
                    {item.question}
                    <ArrowIcon isOpen={activeIndex === index}><ExpandMoreOutlined /></ArrowIcon>
                  </Question>
                  <Answer
                    isOpen={activeIndex === index}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </FaqItem>
              ))}
            </FaqContainer>
          </div>
        </FaqBox>
        <RegulationBox>
          <Regulation>
            <div>
              <h2>Acesse e confira o regulamento completo</h2>
              <Button href="https://qrco.de/regulamento-cavalinho" target="_blank">
                Consulte aqui
              </Button>
            </div>
            <img src={AliInCellphone} alt="Mulher segurando o celular e abrindo o app da AliCrédito" />
          </Regulation>
        </RegulationBox>
        <Footer>
          <LpFooter />
        </Footer>
      </Container>
    );
  }

  return null;
};

export default CavalinhoLandingPageContent;
